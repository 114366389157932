import React from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
// import { navigate } from "gatsby"

import { LayoutQuoting, Title, SectionCenter } from "../components"
import InCAFollowUp from "../components/GMSPurchase/InCAFollowUp"
// import { getCurrentPath } from "../redux/actions"
// import { mq } from "../utils/media-query"
import { TravellersCard } from "../components"

const GetQuotePurpose = ({ location, getCurrentPath }) => {
  // const onGoBack = () => {
  //   navigate("/")
  // }

  const dummyTraveller = {
    first_name: "",
    last_name: "",
    birth_date: "",
    relationship_status: "policyholder",
    gender: "",
    is60yrsOrOlder: "",
    id: "policyholder",
    mq: {
      code: "QU-VMQ-1",
      questions: [
        {
          code: "QT-VIS-1",
          answers: [
            {
              code: "q1a1",
              value: -1,
            },
            {
              code: "q1a2",
              value: -1,
            },
            {
              code: "q1a3",
              value: -1,
            },
            {
              code: "q1a4",
              value: -1,
            },
          ],
        },
        {
          code: "QT-VIS-2",
          answers: [
            {
              code: "q2a1",
              value: -1,
            },
            {
              code: "q2a2",
              value: -1,
            },
            {
              code: "q2a3",
              value: -1,
            },
            {
              code: "q2a4",
              value: -1,
            },
            {
              code: "q2a5",
              value: -1,
            },
            {
              code: "q2a6",
              value: -1,
            },
            {
              code: "q2a7",
              value: -1,
            },
            {
              code: "q2a8",
              value: -1,
            },
            {
              code: "q2a9",
              value: -1,
            },
          ],
        },
        {
          code: "QT-VIS-3",
          answers: [
            {
              code: "q3a1",
              value: -1,
            },
            {
              code: "q3a2",
              value: -1,
            },
          ],
        },
      ],
    },
  }
  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <Title title="testing element..." fontColor="var(--clr-primary-300)" />
        <SectionCenter>
          <TravellersCard traveller={dummyTraveller} />
        </SectionCenter>
      </section>
    </LayoutQuoting>
  )
}

export default connect(null, null)(GetQuotePurpose)
